import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollSmoother } from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener("DOMContentLoaded", function (e) {
  let container = document.querySelector(".pictures-grid");

  if (container) {
    // l'animation GSAP permettant de scroller
    let slider = gsap.to(container, {
      x: () =>
        -(container.scrollWidth - document.documentElement.clientWidth) -
        parseInt(window.getComputedStyle(container).marginLeft) -
        parseInt(window.getComputedStyle(container).marginRight) +
        "px",
      scrollTrigger: {
        trigger: ".pictures-slider",
        start: "top top",
        // end: "+=50%",
        end: () => "+=" + container.offsetWidth,

        scrub: true,
        pin: true,
        // anticipatePin: 1,
      },
    });

    // le code suivant écoute le redimensionnement de la fenêtre et actualise le slider
    window.addEventListener("resize", () => {
      let progress = slider.totalProgress();
      slider.progress(0);
      slider.invalidate();
      slider.totalProgress(progress);
    });
  }

  // let smoother = ScrollSmoother.create({
  //   wrapper: "#smooth-wrapper",
  //   content: "#smooth-content",
  //   smooth: 2,
  //   effects: true,
  // });

  // var getSliderWidth = (el) => {
  //   let sliderOffset =
  //     -document.querySelector(el).offsetWidth + window.innerWidth;
  //   return sliderOffset;
  // };

  // let sections = gsap.utils.toArray(".pictures-container-panels");

  // gsap.to(".pictures-wapper", {
  //   scrollTrigger: {
  //     trigger: ".pictures-container",
  //     scrub: true,
  //     pin: true,
  //     start: "top top",
  //     end: "+=6000px",
  //   },
  //   x: "-50%",
  //   duration: 2,
  // });

  // let slider = gsap.to(".pictures-wapper", {
  //   x: () => getSliderWidth(".pictures-wapper"),
  //   scrollTrigger: {
  //     trigger: ".pictures-container",
  //     start: "top top",
  //     end: "+=400%",
  //     markers: true,
  //     scrub: true,
  //     pin: true,
  //   },
  // });

  // window.addEventListener("resize", () => {
  //   let progress = slider.totalProgress();
  //   slider.progress(0);
  //   slider.invalidate();
  //   slider.totalProgress(progress);
  // });

  const hamburger = document.querySelector(".nav-navigation-container");
  const navMenu = document.querySelector(".nav-navigation-buttons");
  const navMenuBackground = document.querySelector(
    ".nav-navigation-background"
  );
  const cursor = document.querySelector(".nav-cursor");

  hamburger.addEventListener("click", () => {
    hamburger.classList.toggle("active");
    navMenu.classList.toggle("active");
    navMenuBackground.classList.toggle("active");
  });

  const ball = document.querySelector(".nav-cursor");

  let mouseX = 0;
  let mouseY = 0;

  let ballX = 0;
  let ballY = 0;

  let speed = 0.2;

  function animate() {
    let distX = mouseX - ballX;
    let distY = mouseY - ballY;

    ballX = ballX + distX * speed;
    ballY = ballY + distY * speed;

    ball.style.left = ballX + "px";
    ball.style.top = ballY + "px";

    requestAnimationFrame(animate);
  }
  animate();

  document.addEventListener("mousemove", mouseMoveHandler);

  function mouseMoveHandler(event) {
    let scrollLeft =
      window.pageXOffset !== undefined
        ? window.pageXOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollLeft;
    let scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;

    mouseX = event.pageX - scrollLeft;
    mouseY = event.pageY - scrollTop;
  }

  window.addEventListener("load", () => {
    const loader = document.querySelector(".loader");

    if (loader) {
      loader.classList.add("loader--hidden");

      loader.addEventListener("transitionend", () => {
        loader.remove();
      });
    }
  });

  var partNum = 75;

  var c = document.getElementById("c");
  var ctx = c.getContext("2d");

  var w = window.innerWidth;
  var h = window.innerHeight;

  var mouse = {
    x: w / 2,
    y: 0,
  };

  document.addEventListener(
    "mousemove",
    function (e) {
      mouse.x = e.clientX || e.pageX;
      mouse.y = e.clientY || e.pageY;
    },
    false
  );

  var particles = [];
  for (var i = 0; i < partNum; i++) {
    particles.push(new particle());
  }

  function particle() {
    this.x = Math.random() * w - w / 6;
    this.y = Math.random() * h;

    this.r = Math.random() * 7.5 + 3.25;
  }

  var draw = function () {
    c.width = w;
    c.height = h;

    for (var t = 0; t < particles.length; t++) {
      var p = particles[t];
      var nowX = p.r + mouse.x / 4.6;
      var nowY = p.r + mouse.y / 4.6;
      var color = "#fff";

      if (p.r < 10) {
        nowX = p.x + mouse.x / 0.5;
        nowY = p.y + mouse.y / 0.5;
      }
      if (p.r < 9) {
        nowX = p.x + mouse.x / 2;
        nowY = p.y + mouse.y / 2;
      }
      if (p.r < 8) {
        nowX = p.x + mouse.x / 3.5;
        nowY = p.y + mouse.y / 3.5;
      }
      if (p.r < 7) {
        nowX = p.x + mouse.x / 5;
        nowY = p.y + mouse.y / 5;
      }
      if (p.r < 6) {
        nowX = p.x + mouse.x / 6.5;
        nowY = p.y + mouse.y / 6.5;
      }
      if (p.r < 5) {
        nowX = p.x + mouse.x / 8;
        nowY = p.y + mouse.y / 8;
      }
      if (p.r < 4) {
        nowX = p.x + mouse.x / 9.5;
        nowY = p.y + mouse.y / 9.5;
      }
      if (p.r < 3) {
        nowX = p.x + mouse.x / 11;
        nowY = p.y + mouse.y / 11;
      }
      if (p.r < 2) {
        nowX = p.x + mouse.x / 12.5;
        nowY = p.y + mouse.y / 12.5;
      }
      if (p.r < 1) {
        nowX = p.x + mouse.x / 15;
        nowY = p.y + mouse.y / 15;
      }

      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.arc(nowX, nowY, p.r, Math.PI * 2, false);
      ctx.fill();
    }
  };

  setInterval(draw, 33);

  // window.oncontextmenu = function () {
  //   return false;
  // };

  const VideoPLay = document.querySelectorAll(
    ".content-video-container-player"
  );
  // const Activaterer = document.querySelector(".content-video-content");
  if (VideoPLay) {
    VideoPLay.forEach((singleVideo) => {
      singleVideo.addEventListener("mouseover", function (e) {
        // console.log("play");
        singleVideo.play();
      });
      singleVideo.addEventListener("mouseout", function (e) {
        singleVideo.pause();
      });
    });
  }
});

// THREE

const scene = new THREE.Scene();

const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);

camera.position.y = 1;
camera.position.z = 3;
camera.lookAt(0, 0, 0);

const renderer = new THREE.WebGLRenderer({
  canvas: document.querySelector("#bg"),
});

renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, innerHeight);
camera.position.setZ(30);

const geometry = new THREE.TorusGeometry(10, 3, 16, 100);
const material = new THREE.MeshStandardMaterial({
  color: 0xff6347,
});
// const torus = new THREE.Mesh(geometry, material);

// scene.add(torus);

const pointLight = new THREE.PointLight(0xfffff);
pointLight.position.set(20, 20, 20);

const ambientLight = new THREE.AmbientLight(0xfffff);
scene.add(pointLight, ambientLight);

// const lightHelper = new THREE.PointLightHelper(pointLight);
// const gridHelper = new THREE.GridHelper(200, 50);  >>>> in scene add gridHelper
scene.add();

const controls = new OrbitControls(camera, renderer.domElement);

controls.target.set(0, 0, 0);

controls.enablePan = false;

controls.enableZoom = false;

controls.enableDamping = true;

controls.autoRotate = true;
controls.autoRotateSpeed = 0.8; // 30 seconds per orbit when fps is 60

// controls.maxPolarAngle = false;
// controls.minPolarAngle = false;

function addStar() {
  const geometry = new THREE.SphereGeometry(0.2, 100, 100);
  const material = new THREE.MeshStandardMaterial({ color: 0x00000 });
  const star = new THREE.Mesh(geometry, material);

  const [x, y, z] = Array(3)
    .fill()
    .map(() => THREE.MathUtils.randFloatSpread(150));

  star.position.set(x, y, z);
  scene.add(star);
}

Array(1100).fill().forEach(addStar);

const backgroundwhite = new THREE.TextureLoader().load("White-BG.jpg");
scene.background = backgroundwhite;

function animate() {
  requestAnimationFrame(animate);

  // torus.rotation.x += 0.01;
  // torus.rotation.y += 0.005;
  // torus.rotation.z += 0.01;

  controls.update();

  renderer.render(scene, camera);
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, innerHeight);
}

window.addEventListener("resize", onWindowResize, false);

animate();

// const controls = new OrbitControls(camera, renderer.domElement);
